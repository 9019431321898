export default () => (
    {
        search: '',
        // init() {
        //     $watch('search', (value) => {
        //         if(value.trim() !== '') {
        //             const url = new URL(window.location);
        //             url.searchParams.set('s', value);
        //             url.searchParams.delete('page');
        //             window.history.replaceState({}, '', url);
        //         }
        // })
        init() {
            htmx.process(htmx.find('#search-input'));
        }
        // }
    }
);